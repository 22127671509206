import React from "react";
import siteDetails from "../utils/datas";
import { Link } from "react-router-dom";

const { routes } = siteDetails;
const Links = ({
  className,
  links,
  type,
  ...props
}: {
  className?: string;
  links?: {
    label: string;
    path: string;
    id?: number | string;
    hidden?: boolean;
    linkType?: string;
    icon?: string;
  }[];
  type?: "icon" | "link";
}) => {
  if (!links) {
    links = routes;
  }

  return (
    <ul className={`link-container ${className ? className : ""}`} {...props}>
      {links
        .filter(link => !link.hidden)
        .map((routeName, index): React.ReactNode =>
          <li key={index}>
            {routeName.linkType === "hash"
              ? <a href={routeName.path}>
                  {type === "icon" && routeName.icon ? <img src={routeName.icon} alt="" /> :routeName.label}
                </a>
              : <Link to={routeName.path}>
                  {type === "icon" && routeName.icon ? <img src={routeName.icon} alt="" /> :routeName.label}
                </Link>}
          </li>
        )}
    </ul>
  );
};

export default Links;
