import React from 'react'
import PageContainer from './PageContainer'
import siteDetails from '../utils/datas';
import {Box} from "@mui/material"
import Links from './Links';
import Logo from './Logo';

const { colorScheme, colorSchemeSecondary } = siteDetails;

const Footer = () => {
  return (

    <>
      <PageContainer
        paddingY={9}
        paddingBottom={0}
        bgcolor={colorScheme}
        className="footer"
      >

        <Box
          color={colorSchemeSecondary}
          paddingBottom={9}
          display="flex"
          alignItems="flex-start"
          flexWrap="wrap"
          justifyContent="space-between"
        >

          <div className="footer-content footer-details">

            <Logo
              className='color-primary-cream footer-logo weight-600'
            />

            <p className="description weight-600">Special Technology A Giant</p>

            <p className="description italics text">... out of this world’s creativity</p>

            <div className="footer-form flex-container column">
              <label htmlFor='email' className="text weight-500">Subscribe to our newsletter</label>

              <div className="flex-container full-width wrap">
                <input type="email" id="email" className='subscribe-email-field bg-primary-base' placeholder="Email Address" />

                <button className='submit-subscribe-email button bg-primary-gold color-white'>
                  Subscribe
                </button>

              </div>

            </div>


          </div>
          <div className="footer-content">

            <h3 className="text">Useful Links</h3>

            <Links
              className="list-style-none flex-container column footer-links" 
            
            />

          </div>

        </Box>



      </PageContainer>

      <Box
        paddingX={2}
        bgcolor={colorScheme}
        color={colorSchemeSecondary}
        
      >

        <Box
          paddingY={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          sx={{
            borderTop: `1px solid ${colorSchemeSecondary}`
          }}
        >
          
          <p>&copy; 2023 Special Technology A Giant</p>

        </Box>


      </Box>
    
    </>
  )
}

export default Footer
