import { Box } from '@mui/material'
import React from 'react'
import siteDetails from '../utils/datas'

const {images: {generalReviewImage}} = siteDetails;

const ReviewCard = ({text, image, ...props}: {text: string, image?: any}) => {

    if (!image) {
        image = generalReviewImage;;
    }

  return (

    
    <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        className="review-card"
        sx={{
            paddingRight: "100px",
        }}
        {...props}
    >
        <Box
            width="30%"
            height="auto"
            className="review-card-image"
        >

            <img style={{
                width: "100%"
            }} src={image} alt="Special Technology A Giant" />

        </Box>

        <Box
            padding={3}
            flex={1}
            className="review-card-content"
            position="relative"
            sx={{
                '&:before':{
                    width: "80px",
                    height: "80px",
                    border: "3px solid #DDCF93",
                    content: '""',
                    position:"absolute",
                    top: "-45px",
                    left: "-45px",
                    zIndex: "-1"

                },

                '&:after':{
                    width: "80px",
                    height: "80px",
                    border: "3px solid #DDCF93",
                    content: '""',
                    position:"absolute",
                    bottom: "-45px",
                    right: "-45px",
                    zIndex: "-1"

                },
                
            }}
            bgcolor="#FBF0D5"
            borderRadius={2}
            color="black"
        >

            <p>{text}</p>

        </Box>
      
    </Box>
  )
}

export default ReviewCard
