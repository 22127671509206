import MaintenanceLottie from "../lottie_json/maintenance.json"
import PageNotFoundLottie from '../lottie_json/pagenotfound.json';
import HomeBanner from "../images/banner_image.png";
import DotOne from "../images/dots-one.png"
import DotTwo from "../images/dots-two.png"
import DotThree from "../images/dots-three.png"
import DotFour from "../images/dots-four.png"
import MonitorIcon from "../icons/mdi_monitor-mobile-phone.svg"
import WebIcon from "../icons/fluent-mdl2_web-environment.svg"
import MobileIcon from "../icons/icomoon-free_mobile.svg"
import VideoIcon from "../icons/ant-design_video-camera-outlined.svg"
import BrandIcon from "../icons/eos-icons_abstract-incomplete.svg"
import GraphicsIcon from "../icons/simple-icons_taichigraphics.svg"
import UiImage from "../images/ui.png"
import GraphicsImage from "../images/graphic.png"
import WebDevImage from "../images/web.png"
import AppImage from "../images/app.png"
import VideoImage from "../images/video.png"
import BrandImage from "../images/brand.png"
import GeneralReviewImage from "../images/review_image_gen.png"
import InstagramLogo from "../icons/fa6-brands_square-instagram.svg"
import FacebookLogo from "../icons/fe_facebook.svg"
import TwitterLogo from "../icons/fa6-brands_square-twitter.svg"
import MailLogo from "../icons/material-symbols_mail-outline-rounded.svg"
import HomeIcon from "../icons/home.svg"
import ServiceIcon from "../icons/services.svg"
import ContactIcon from "../icons/contact.svg"
import ProjectOne from "../images/project_1.png"
import ProjectTwo from "../images/project_2.png"
import ProjectThree from "../images/project_3.png"
import ProjectFour from "../images/project_4.png"
import ProjectFive from "../images/project_5.png"
import Rings from "../images/circle.png"
import Loader from "../images/loader.gif"


interface SiteDetailsPropsconst  {
    siteNameShort: string;
    siteNameFull: string;
    logo: string;
    socials: {
        mail: {
            label: string;
            icon: string;
            link: string;
        };
        facebook: {
            label: string;
            icon: string;
            link: string;
        };
        instagram: {
            label: string;
            icon: string;
            link: string;
        };
        twitter: {
            label: string;
            icon: string;
            link: string;
        };
        linkedIn: {
            label: string;
            icon: string;
            link: string;
        };
    };

    colorScheme: string;
    colorSchemeSecondary: string;
    headerHeight: string;

    routeName: {
        home: string;
        Services: string;
        courses: string;
        contactUs: string;

    },
    routes: {
            label: string;
            id: number;
            exact: boolean;
            component?: React.ReactNode | (() => JSX.Element) | (()=> React.ReactNode);
            seoEnabled?: boolean;
            linkType: "hash" | "router";
            hidden?: boolean;
            path: string;
            icon?: string

        }[];
    lotties: {
        maintenance: any;
        PageNotFound: any;
    };

    images: {
        homeBanner: string;
        dotOne: string;
        dotTwo: string;
        dotThree: string;
        dotFour: string;
        generalReviewImage: string;
        rings: string;
        loader: string;

    };

    icons: {
        

    };

    whatWeDo: {
            icon: string;
            title: string;
            description: string;
            backgroundImage: string;
        }[];

    reviews: {
            review: string;
        }[]

    about: {
        title: string;
        content: string;
    },
    
    worksCategory: string[];

    socialArr: {
        label: string;
        icon: string;
        link: string;
    }[],

    projects: {
        title: string,
        image: string,
        description: string,
        link: string,
        category: string
    }[]
}

const siteDetails : SiteDetailsPropsconst = {
    siteNameShort: "STAG",
    siteNameFull: "Special Technology A Giants",
    logo: "",
    socials: {
        // mail: "contact@stagofficial.com",
        mail: {
            
            label: "contact@stagofficial.com",
            icon: MailLogo,
            link: "mailto:contact@stagofficial.com"
        },
        facebook: {
            label: "facebook.com/stagteamofficial",
            icon: FacebookLogo,
            link: "https://facebook.com/stagteamofficial"
        },
        instagram: {
            label: "instagram.com/stagteamofficial",
            icon: InstagramLogo,
            link: "https://instagram.com/stagteamofficial"
        },
        twitter: {
            label: "twitter.com/stagteamofficia",
            icon: TwitterLogo,
            link: "https://twitter.com/stagteamofficia"
        },
        linkedIn: {
            label: "linkedin.com/in/stagofficial",
            icon: "",
            link: "https://linkedin.com/in/stagofficial"
        }
    },
    colorScheme: "#181F18",
    colorSchemeSecondary: "#DDCF93",
    headerHeight: "70px",
    routeName: {
        home: "",
        Services: "services",
        courses: "courses",
        contactUs: "contact",

    },
    routes: [
        {
            path: "#home",
            label: "Home",
            id: 1,
            exact: true,
            linkType: "hash",
            seoEnabled: true,
            icon: HomeIcon

        },

        {
            path: "#services",
            label: "Services",
            id: 2,
            exact: false,
            linkType: "hash",
            icon: ServiceIcon


        },

        {
            path: "",
            label: "Courses",
            id: 3,
            exact: false,
            hidden: true,
            linkType: "router",
            icon: ""


        },

        {
            path: "#contact",
            label: "Contact us",
            id: 4,
            exact: false,
            linkType: "hash",
            icon: ContactIcon


        }
    ],
    lotties: {
        maintenance: MaintenanceLottie,
        PageNotFound: PageNotFoundLottie
    },

    images: {
        homeBanner: HomeBanner,
        dotOne: DotOne,
        dotTwo: DotTwo,
        dotThree: DotThree,
        dotFour: DotFour,
        generalReviewImage: GeneralReviewImage,
        rings: Rings,
        loader: Loader

    },

    icons: {
        

    },

    whatWeDo: [
        {
            icon: MonitorIcon,
            title: "UI/UX design",
            description: "We design the layout, appearance, and functionality of your product or service keeping in mind the user's feelings, attitudes, and perceptions of the product or service.",
            backgroundImage: UiImage
        },

        {
            icon: WebIcon,
            title: "Web development",
            description: "We focus on developing best functionalities of websites and implementing security measures to protect them. We use various programming languages and frameworks, including HTML, CSS, JavaScript, React Js, Next Js, Node js and PHP, to create websites that are visually appealing and easy to use.",
            backgroundImage: WebDevImage
        },

        {
            icon: MobileIcon,
            title: "App design",
            description: "We focus on designing, building, and testing mobile applications to ensure that they are functional and meet the specific needs of the user. We also develop across all platforms (e.g. iOS, Android, Windows).",
            backgroundImage: AppImage
        },

        {
            icon: VideoIcon,
            title: "Video marketing",
            description: "We recognize that video marketing is powerful tool for promoting businesses and increasing brand awareness. We work to create videos which convey the company's information in a visually appealing and interactive way.",
            backgroundImage: VideoImage
        },

        {
            icon: BrandIcon,
            title: "Brand identity",
            description: "We understand that a strong brand identity helps to differentiate a company from its competitors and establish a connection with its target audience. Thus, we focus on building brand awareness, loyalty, and value through the brand's name, logo, color scheme, typography, messaging, and tone of voice to make it distinct and recognizable to consumers.",
            backgroundImage: BrandImage
        },

        {
            icon: GraphicsIcon,
            title: "Graphics design",
            description: "Graphic design is the art of creating visual content for various purposes, such as communication, branding, marketing, and advertising. We combine text, images, and other elements with a variety of tools, such as typography, colour, composition, and layout, to create visual designs that effectively communicate information and engage the audience.",
            backgroundImage: GraphicsImage

        }


    ],

    reviews: [
        {
            review: "I had the pleasure of working with this creative design agency on a rebranding project for my company. From start to finish, their team was professional, responsive, and most importantly, incredibly talented. They truly understood our vision and brought it to life in a way that exceeded all of our expectations. Their attention to detail and commitment to quality was evident in every aspect of the project. I highly recommend this agency to anyone in need of top-notch design work."
        },

        {
            review: "I am extremely impressed with the web development services provided by STAG. Their team was highly skilled and able to create a website that not only looks great but also performs exceptionally well. They were also very communicative throughout the entire process, making it a stress-free experience."
        },

        {
            review: "I was blown away by the UI/UX design services provided by STAG. Their team was able to create a user-friendly and visually appealing website that perfectly aligned with my brand's image. The end result exceeded my expectations, and I'm very happy with the service provided"
        },

        {
            review: "STAG's video marketing services are top-notch! Their team was able to create a high-quality video that perfectly captured my brand's message and values. I've received a lot of positive feedback from my audience, and I would definitely work with them again!"
        }
    ],

    about: {
        title: "OUT OF THE BOX CREATORS",
        content: "At Special Technology A Giant, we are a team of passionate and talented creatives who are dedicated to producing top-quality design work for our clients. We believe that good design has the power to transform businesses and organizations, and we strive to push the boundaries of traditional design to deliver exceptional work that helps our clients stand out."
    },

    socialArr: [
        {
            label: "instagram.com/stagteamofficial",
            link: "https://www.instagram.com/stagteamofficial",
            icon: InstagramLogo
        },

        {
            label: "facebook.com/stagteamofficial",
            link: "https://www.facebook.com/stagteamofficial",
            icon: FacebookLogo
        },

        {
            label: "twitter.com/stagteamofficia",
            link: "https://www.twitter.com/stagteamofficia",
            icon: TwitterLogo
        },

        {
            label: "contact@stagofficial.com",
            link: "mailto:contact@stagofficial.com",
            icon: MailLogo
        }
    ],

    projects: [
        {
            title: "Agrotivo Website",
            description: "",
            image: ProjectOne,
            link: "",
            category: "Web developments"
        },

        {
            title: "Health Website",
            description: "",
            image: ProjectTwo,
            link: "",
            category: "Web developments"
        },

        
        {
            title: "COVID-19 App",
            description: "",
            image: ProjectFive,
            link: "",
            category: "App developments"
        },

        {
            title: "Education Website",
            description: "",
            image: ProjectThree,
            link: "",
            category: "Web developments"
        },

        {
            title: "Music App",
            description: "",
            image: ProjectFour,
            link: "",
            category: "App developments"
        }


    ],

    worksCategory: [ "Web developments", "Graphics", "Video marketing", "App developments"]


}


export default siteDetails;