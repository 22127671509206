import React from 'react'
import {Link} from "react-router-dom"
import siteDetails from '../utils/datas';

const {routeName} = siteDetails

const Logo = ({className}: {className?: string}) => {
  return (
    
    <Link to={routeName.home} className={`text-lg ${className?  className :""}`}>S.T.A.G</Link>
  )
}

export default Logo
