import { Box } from '@mui/material'
import React from 'react'

interface FormContentInterface{
    width?: string,
    height?: string,
    label: string,
    id?: string,
    inputClassName?: string,
    labelClassName?: string,
    error?: string,
    type?: string,
    textArea?: boolean,
    placeholder?: string,
    value?: string,
    onChange?: any
}

const FormContent :React.FC<FormContentInterface> = ({
    width,
    height,
    label,
    id,
    inputClassName,
    labelClassName,
    error,
    type,
    textArea,
    placeholder,
    value,
    onChange,
    ...props}) => {
  return (
    <Box
        width={width? width : "100%"}
        height={height? height : "auto"}
        className="form-content"
        display="flex"
        flexDirection="column"
        
    >

        <label htmlFor={id? id: ""}>{label}</label>
        {textArea? (

            <>
            

                <textarea onChange={onChange} value={value} className={`textarea-field ${inputClassName?  inputClassName: ""}`} id={`textarea-field ${id?  id: ""}`} {...props}></textarea>


            </>

        ): (

            <>
            
                <input onChange={onChange} value={value} type={type? type: "text"} placeholder={placeholder? placeholder: ""} className={` ${inputClassName?  inputClassName: ""}`} id={`${id?  id: ""}`} {...props} />

            </>

        )}

        <p className='text-sm color-danger mt-1'>{error}</p>


      
    </Box>
  )
}

export default FormContent
