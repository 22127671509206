import React, { useContext, useReducer } from 'react'
import { siteInitialValue, siteReducer } from '../reducers';
import { OPEN_MOBILE_MENU, CLOSE_MOBILE_MENU } from '../utils/actions';

const SiteContext = React.createContext({
  ...siteInitialValue,
  openMobileMenu: () => {},
  closeMobileMenu: () => {}

});

interface SiteContextType{
  children: React.ReactNode

}
export const SiteProvider = ({children}: SiteContextType) => {
    const [state, dispatch] = useReducer(siteReducer, siteInitialValue);


    const openMobileMenu = () => {
      dispatch({type: OPEN_MOBILE_MENU})
    }

    const closeMobileMenu = () => {
      dispatch({type: CLOSE_MOBILE_MENU})
    }


  return (

    <SiteContext.Provider value={{...state, openMobileMenu, closeMobileMenu}}>

      {children}

    </SiteContext.Provider>
    
  )
}

const useSiteContext = () => {
    return useContext(SiteContext);
}

export default useSiteContext
