import React from 'react'
import {Grid} from "@mui/material"

const PageContainer = ({children, ...props}) => {
  return (
    
    <Grid 
        container 
        width="100%"
        display="flex"
        justifyContent="center"
        position="relative"
        {...props}
    >
        
        <Grid
            item
            height="100%"
            xs={11}
        >

             {children}

        </Grid>
    </Grid>
  )
}

export default PageContainer
