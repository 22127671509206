import React from 'react'
import {Button as AntdButton} from "antd";


interface Props{
    className?: string;
    loading?: boolean;
    text: string;
    onClick?: () => void | never
}

const Button: React.FC<Props> = ({ className, loading, text, onClick, ...props }) => {
  return <AntdButton loading={loading} className={`button ${className ? className : ""}`} onClick={onClick} {...props}>
      {text}
    </AntdButton>;
};

export default Button
