
import React from 'react'
import siteDetails from '../utils/datas';
import PageContainer from './PageContainer';
import {Box} from "@mui/material"
import Logo from './Logo';
import Links from './Links';
import MobileLink from './MobileLink';
// import {FaBars} from "react-icons/fa"
// import { useSiteContext } from '../context';

const {colorScheme, headerHeight} = siteDetails;

const Nav = () => {
    // const {openMobileMenu} = useSiteContext();
  return (

    <>
        <PageContainer
            bgcolor={colorScheme}
            height={headerHeight}
            color="white"
            position="fixed"
            className="nav-bar"
            zIndex={999}
        >

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height="100%"
            
            >

                <Logo
                    className="nav-bar-logo"
                
                />

                <Links 
                    className='flex-container list-style-none desktop-link'

                
                />

                <Links 
                    className='flex-container list-style-none mobile-open-link gap-7'
                    type='icon'

                
                />

                {/* <button onClick={openMobileMenu} className='mobile-open-button stripped-button text-lg'>
                    <FaBars />
                </button> */}

            </Box>

            

        </PageContainer>

        <MobileLink />
    
    </>

  )
}

export default Nav
