import React from 'react'
import {Box} from "@mui/material"

const WhatWeDoCard = ({icon, title, description, backgroundImage}: {icon: React.ReactNode, title: string, description: string, backgroundImage?: any}) => {
  return (
    <Box 
        width="100%"
        height="300px"
        className="what-we-do-card"
        borderRadius={2}
        overflow="hidden"
        sx={{
            background: `url(${backgroundImage})`,
            backgroundSize: "cover"
        }}
        
    >

            <Box
                bgcolor="rgba(251, 240, 213, 0.4)"
                height="100%"
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                flexDirection="column"
                padding={3}
                gap={2}
                sx={{
                    backdropFilter:"blur(3px)"
                }}
            >

                <div className="icon">
                    {icon}
                </div>

                <h2 className="title text-md">{title}</h2>
                <p className="description text">{description}</p>

            </Box>

      
    </Box>
  )
}

export default WhatWeDoCard
