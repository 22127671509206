import React from 'react'

const SomethingWentWrong = () => {
  return (
    <div>
      <h1>Oops! Something went wrong</h1>
    </div>
  )
}

export default SomethingWentWrong
