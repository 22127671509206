import React from "react";
import { Box } from "@mui/material";

const PageHeaders = ({
  textOne,
  textTwo,
  className,
  linePosition,
  borderedText,
  lineColor,
  lineWidth,
  identity,
  ...props
}: {
  textOne?: string;
  textTwo?: string;
  linePosition?: "top" | "left" | "right" | "bottom";
  borderedText?: "text-one" | "text-two";
  className?: string;
  lineColor?: string;
  lineWidth?: number | string;
  identity: number | string;
}) => {
  if (!lineColor) {
    lineColor = "rgba(222, 208, 148, 0.4)";
  }

  return <Box {...props} paddingBottom={3} className={`${className ? className : ""}`} position="relative">
      {lineColor && <style dangerouslySetInnerHTML={{ __html: `
        .bordered-${identity}::after{
          background: ${lineColor} !important;
        }
        ` }} />}

      <h1 className="text-lg">
        {textOne && <span className={`page-header-text ${borderedText === "text-one" ? `bordered bordered-${identity} weight-700` : "weight-200"} ${linePosition}`} style={{ border: borderedText === "text-one" ? `${lineWidth ? lineWidth : "2px"} solid ${lineColor ? lineColor : "rgba(222, 208, 148, .4)"}` : "none" }}>
            {textOne}
          </span>}
        &nbsp;
        {textTwo && <span className={`page-header-text ${borderedText === "text-two" ? `bordered bordered-${identity} weight-700` : "weight-200"} ${linePosition}`} style={{ border: borderedText === "text-two" ? `${lineWidth ? lineWidth : "2px"} solid ${lineColor ? lineColor : "rgba(222, 208, 148, .4)"}` : "none" }}>
            {textTwo}
          </span>}
      </h1>
    </Box>;
};

export default PageHeaders;
