import { DISABLE_MAINTENANCE_MODE, ENABLE_MAINTENANCE_MODE, OPEN_MOBILE_MENU, CLOSE_MOBILE_MENU } from '../utils/actions';

interface reducerAction {
    type: string,
    payload?: {} | {}[] | string[] | boolean | number


}

interface stateAnnotation {
    maintenanceMode: boolean,
    mobileMenuOpened: boolean,
    theme: string,

}


export const initialValue : stateAnnotation = {
    maintenanceMode: false,
    mobileMenuOpened: false,
    theme: "default",
}


export const reducer = (state: stateAnnotation, action : reducerAction) => {

    var {type} = action;

    switch (type) {
      case ENABLE_MAINTENANCE_MODE:
        return { ...state, maintenanceMode: true };

      case DISABLE_MAINTENANCE_MODE:
        return { ...state, maintenanceMode: false };

      case OPEN_MOBILE_MENU:
        return { ...state, mobileMenuOpened: true };

      case CLOSE_MOBILE_MENU:
        return { ...state, mobileMenuOpened: false };

      default:
        return state;
    }


}