import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import siteDetails from '../../utils/datas';

const {lotties} = siteDetails

const PageNotFound = () => {
  return (
    <div>

        <Player 
        
            src={lotties.PageNotFound}
            loop
            autoplay
            style={{
                maxWidth: "500px"
            }}

        />
      
    </div>
  )
}

export default PageNotFound
