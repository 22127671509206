import { Box, Grid } from '@mui/material'
import React, {useCallback, useState, useRef} from 'react'
import siteDetails from '../utils/datas';
import { Button, FormContent, /* InnerNav, */ PageContainer, PageHeaders, /* ProjectCard, */ ReviewCard, WhatWeDoListing } from '../components';
import { toast } from 'react-toastify'

import emailjs from '@emailjs/browser';

import "../styles/homePage.css";
import Carousel from 'react-material-ui-carousel';
import { Link } from 'react-router-dom';

const {images, reviews, about, routeName, socialArr/* , projects */} = siteDetails;
const HomePage = () => {
  const form = useRef<any>(null)
  const [loading, setLoading] = useState(false)

  const [mailFormState, setMailFormState] = useState({
    name: "",
    nameErr: "",
    email: "",
    emailErr: "",
    message: "",
    messageErr: ""
  })

  const submitForm = useCallback(({name, email, message} : {name: string, email: string, message: string})=> {

    

    if(name.trim() === ""){
      setMailFormState(prevState => ({
        ...prevState,
        nameErr: "Please input your name"
      }))
    }else if(email.trim() === ""){
      setMailFormState(prevState => ({
        ...prevState,
        emailErr: "Please input your name"
      }))
    }else if(message.trim() === ""){
      setMailFormState(prevState => ({
        ...prevState,
        messageErr: "Please input your message"
      }))
    }else{

      setLoading(true)
      emailjs.send('service_hog0sgl', 'template_oi4ceo9',{
        from_name: name,
        message: message,
        to_email: email,
        from_email: "contact@stagofficial.com",
      }, 'OTiktQIG2SR8GtGGF')
      .then((result) => {
          toast.success("Message sent successfuly!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",

          });
          setMailFormState({
            name: "",
            nameErr: "",
            email: "",
            emailErr: "",
            message: "",
            messageErr: ""
          })

      }, (error) => {
        
        toast.error("Something went wrong! please try again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",

          });
      }).finally(()=>{
        setLoading(false)
      });


    }


  }, [])

  

  return (
    <>
    
      <Box
        width="100%"
        height="auto"
        minHeight="100vh"
        display="flex"
        alignItems="center"
        bgcolor="#FBF0D5"
        paddingY={7}
        className="banner"
        sx={{
          overflowX: "hidden"
        }}
        id="home"
      >

        <PageContainer
          marginTop={-2}
        >

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="banner-content-container"

          >

            <div className="banner-content banner-details">
              <p className="banner-subtitle-one button inline-flex text-md bg-primary-base curvy-border">

                Create | Teach | Inspire

              </p>

              <h1 className="text-lg-x3-5 banner-title">It's time to <span className="color-primary-gold">expand</span> your horizon</h1>

              <p className="subtitle-two text-lg">
                From brand creation to learning new skills to content creation, we got you covered. Sounds interesting?
              </p>

              <a href="#message" className='button banner-cta bg-primary-gold color-white text-md'>
                Message Us
              </a>
            </div>

            <Box className="banner-content banner-image">
              <img src={images.homeBanner} alt="special technology a giants" />
            </Box>

          </Box>

        </PageContainer>

      </Box>

      <Box
        paddingY={7}
        paddingTop={12}
        className="relative what-we-do"
        sx={{
          overflow: "hidden"
        }}
        id="services"
      >


        <img src={images.dotOne} alt="dots" className="dot-image absolute top right" />

        <PageContainer>

          <PageHeaders 
            textOne='WHAT'
            textTwo='WE DO'
            borderedText='text-one'
            className='color-primary-cream'
            linePosition='top'
            identity={1}

          />

          <Box
            mt={7}

          >

            <WhatWeDoListing
              

            />
          </Box>




        </PageContainer>
      </Box>

      

      {/* <PageContainer
        paddingY={15}
        bgcolor="#FBF0D5"
        color="black"
      >

        <PageHeaders 
          textOne='WHAT'
          textTwo='WE HAVE DONE'
          borderedText='text-one'
          className='color-primary-algae'
          linePosition='left'
          lineColor="rgba(0, 0, 0, .3)"
          identity={2}

        />

        <Box
          mt={4}
        
        >

          <Box
            width="100%"
            display="flex"
            alignItems="center"
          >

            <InnerNav
              buttonClassName="color-default text weight-600"
              activeClassName="bg-primary-gold color-white border-radius-half"
              
            />
          </Box>


          <Box>

            {projects.map((data, index)=><ProjectCard className="" {...data} key={index} width="33%"/>)}

          </Box>



        </Box>

      </PageContainer> */}

      <PageContainer
        paddingY={15}
        sx={{
          overflow: "hidden"
        }}
        className="color-primary-base"
      >

        <PageHeaders 
          textOne='WHAT'
          textTwo='PEOPLE SAY'
          borderedText='text-two'
          className='color-primary-base'
          linePosition='right'
          identity={3}

        />

        <p style={{
          marginTop: "50px"
        }} className='weight-400'>What our clients have to say about us</p>

        <Carousel
          animation='slide'
          autoPlay={true}
          duration={500}
          interval={5000}
          sx={{
            marginTop: "40px"
          }}
          // height="auto"
        >

          {reviews.map((review, index)=>

          <ReviewCard key={index} text={review.review} />
          
          )}

        </Carousel>

        

      </PageContainer>

      <PageContainer
        bgcolor="#FBF0D5"
        paddingY={15}
        pb={25}
        color="black"
        className="about-us"
      >

        <Box
          position="absolute"
          bgcolor="#DDCF93"
          top={0}
          width="90%"
          py={5}
          px={6}
          mt={-7}
          zIndex={9}
          display="flex"
          alignItems="center"
          gap={1}
          borderRadius={2}
          sx={{
            left: "50%",
            transform: "translateX(-50%)"
          }}
          className="contact-us-arrow-container"
        >


            <h3>Like what you see?</h3>


            <Box
              display="flex"
              flex={1}
              alignItems="center"
              gap={5}
            >

              <Box
                flex={1}
                alignItems="center"
                display="flex"
                gap={0}
                position="relative"
                className="contact-arrow"

              
              >

                <Box
                  height="3px"
                  bgcolor="black"
                  className="arrow-line"
                ></Box>

                <span className="arrow-right">

                </span>



              </Box>

              <Link to="" className='button color-primary-cream bg-primary-gold'>
                Work With Us
              </Link>

            </Box>

        </Box>

        <PageHeaders 
          textOne='WHO'
          textTwo='WE ARE'
          borderedText='text-one'
          className='color-primary-algae'
          linePosition='left'
          lineColor="rgba(0, 0, 0, .3)"
          identity={4}

        />

        <Grid
          container
          mt={8}
          justifyContent="space-between"
          spacing={3}

        > 

          <Grid
            item
            display="flex"
            alignItems="center"
            xs={12}
            md={6}
            lg={5}
            
          >

            <Box
              border="3px solid black"
              height="400px"
              width="55%"
              minWidth="55%"
              className="out-of-the-box"

            ></Box>

            <h1 className='text-lg-x1-5 text-uppercase'>Out <br /> Of The <br /> Box <br /> Creators</h1>


          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            
          >

            <p className="text-lg full-width">{about.content}</p>
            

            <Link className='button bg-primary-base color-default weight-500 button-md mt-6' to={routeName.home}>
              Discover More
            </Link>

          </Grid>



        </Grid>

        <img src={images.dotTwo} className='dot-image absolute bottom left' alt='' />

      </PageContainer>

      <PageContainer
        bgcolor="#DDCF93"
        paddingY={15}
        pb={25}
        color="black"
        className="contact-us"
        id="contact"
      >

        
        <img src={images.dotThree} className='dot-image absolute top right' alt='' />
        <img src={images.dotFour} className='dot-image absolute bottom left' alt='' />

        <PageHeaders 
          textOne='WANT'
          textTwo='TO KNOW MORE?'
          borderedText='text-one'
          className='color-primary-algae'
          linePosition='left'
          lineColor="#FBF0D5"
          identity={5}
        />

        <Grid
          container
          mt={7}
          spacing={3}
          justifyContent="space-between"

        >

            <Grid item xs={12} md={6} lg={5}
            >

              <h3>Check us out on social media</h3>

              <ul className='list-style-none'>
                {socialArr.map((socialDetails, index) => <li className='mt-2' key={index}>

                  <a className='flex-container align-center gap-2 color-default weight-500' href={socialDetails.link} target="_blank" rel="noreferrer">

                    <span className='icon'>

                      <img src={socialDetails.icon} className='width-small-30' alt={socialDetails.label} />

                    </span>
                    <span className='text'>

                      {socialDetails.label}

                    </span>
                    
                  </a>

                </li>)}
              </ul>

            </Grid>
            <Grid item xs={12} md={6} lg={6}
              display="flex"
              justifyContent="flex-end"
              id="message"
            >

              <Box
                width="100%"
                bgcolor="#FFFFFFCC"
                paddingX={13}
                paddingY={7}
                borderRadius={5}
                position="relative"
                overflow="hidden"
                maxWidth="500px"
                className="contact-us-form"
              >

                <img src={images.rings} alt="rings" className='dot-image absolute top left width-small-60 top-negative-1' />
                <img src={images.rings} alt="rings" className='dot-image absolute top center width-small-60' />
                <img src={images.rings} alt="rings" className='dot-image absolute bottom left width-small-60 bottom-negative-1' />

                <form ref={form} onSubmit={(e)=>{
                  e.preventDefault();
                  submitForm(mailFormState)

                }} className="color-primary-algae flex-container align-center column full-width">

                  <h3>Leave us a message</h3>

                  <FormContent
                    label=''
                    placeholder='Name'
                    id='name'
                    type="text"
                    inputClassName='border-bottom-solid-2 bg-transparent outline-none py-1 mt-2'
                    value={mailFormState.name}
                    onChange={(e)=>{
                      var err = "",
                          val = e.target.value;
                      if(val.trim() === ""){
                        err= "Please input your name"

                      }
                      setMailFormState(prevState => ({
                        ...prevState,
                        name: val,
                        nameErr: err
                      }))
                    }}
                    error={mailFormState.nameErr && mailFormState.nameErr}
                  />

                  <FormContent
                    label=''
                    placeholder='Email'
                    id='email'
                    type='email'
                    inputClassName='border-bottom-solid-2 bg-transparent outline-none py-1 mt-2'
                    value={mailFormState.email}
                    onChange={(e)=>{
                      var err = "",
                          val = e.target.value;
                      if(val.trim() === ""){
                        err= "Please input your email"

                      }
                      setMailFormState(prevState => ({
                        ...prevState,
                        email: val,
                        emailErr: err
                      }))
                    }}
                    error={mailFormState.emailErr && mailFormState.emailErr}
                  />

                  <FormContent
                    label=''
                    placeholder='Write Message'
                    id='message'
                    textArea
                    inputClassName='border-bottom-solid-2 bg-transparent outline-none py-1 mt-2'
                    value={mailFormState.message}
                    onChange={(e)=>{
                      var err = "",
                          val = e.target.value;
                      if(val.trim() === ""){
                        err= "Please input your message"

                      }
                      setMailFormState(prevState => ({
                        ...prevState,
                        message: val,
                        messageErr: err
                      }))
                    }}
                    error={mailFormState.messageErr && mailFormState.messageErr}
                  />

                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      text="Submit"
                      className='bg-primary-gold color-primary-cream py-2 px-4 mt-2'
                      onClick={()=>{submitForm(mailFormState)}}
                      loading={loading}
                    />

                  </Box>



                </form>
              </Box>


            </Grid>

        </Grid>

      </PageContainer>

    </>
  )
}

export default HomePage