import React from 'react';
import { useSiteContext } from './context';
import { SiteMaintenance } from './pages';
import { Footer, Nav } from './components';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function App() {
  const {maintenanceMode} = useSiteContext();

 

  if(maintenanceMode){
    return <SiteMaintenance />
  }

  return (
    <>

      <ToastContainer />

      <Nav />

      <div className="main-body">

        <Outlet />

      </div>

      <Footer />

    </>
  );
}

export default App;
