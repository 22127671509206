import React, { useState } from "react";
import Modal from './Modal';
import {Box} from "@mui/material"
import {MdClose} from "react-icons/md"
import Links from "./Links";
import { useSiteContext } from "../context";

const MobileLink = () => {
    const [right, setRight] = useState("-500px")
    const { mobileMenuOpened, closeMobileMenu } = useSiteContext();
  return (
    <Modal
        active={mobileMenuOpened}
        onActive={()=>{
            setRight("0px")
        }}
        onUnActive={()=>{
            setRight("-500px");
        }}
        className="mobile-link-modal"

    >

        <Box
            position="absolute"
            width="100%"
            height="100vh"
            right={right}
            maxWidth="500px"
            bgcolor="white"
            sx={{
                transition: "0.5s ease all"
            }}
            className="mobile-link-modal-container"

        >

            <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                    borderBottom: "1px solid rgba(0, 0, 0, .2)",
                    padding: "0px 0px 20px 0px"
                }}
            >

                <h3 className="text weight-normal mobile-link-title">Teach | Create | Inspire</h3>

                <button onClick={closeMobileMenu} className="stripped-button text-lg">
                    <MdClose />
                </button>

            </Box>

            <Box
                width="100%"
                mt={2}
            >
                <Links
                    className="list-style-none mobile-links flex-container column"
                />
            </Box>

        </Box>

    </Modal>
  )
}

export default MobileLink
