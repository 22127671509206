import React, { useEffect, useState } from 'react'
import {Box} from "@mui/material"

const Modal = ({className, children, active, onActive, onUnActive, ...props}: {className?: string, active?: boolean, onActive?: () => any,  onUnActive?: () => any, children?: React.ReactNode}) => {

  const [opacity, setOpacity] = useState(0)
  const [display, setDisplay] = useState("none");

  if(!onUnActive){
    onUnActive = () => {

    }
  }

  if (!onActive) {
    onActive = () => {};
  }

  useEffect(()=>{

    if(active){
      setDisplay("block")

      setTimeout((): void =>{
        setOpacity(1)

        if(onActive){
          onActive();
        }
        
      }, 100)

    }else{

      
      if (onUnActive) {
        onUnActive();
      }

      setTimeout((): void =>{
        setOpacity(0);

        setTimeout(()=>{

          setDisplay("none");

        }, 300)
      }, 300)

    }

  }, [active, onUnActive, onActive])

  return (
    <Box
        position="fixed"
        display={display}
        width="100%"
        height="100vh"
        bgcolor="rgba(0, 0, 0, .2)"
        zIndex={999}
        sx={{
          opacity,
          transition: "0.5 ease all"
        }}

    >

      <Box
        width="100%"
        height="100%"
        className={`${className? className : ""}`}
        {...props}
        sx={{
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(0, 0, 0, .2)",
          overflowY: "scroll",
          '&::-webkit-scrollbar':{
            width: "5px"

          },

          '&::-webkit-scrollbar-thumb':{
            color: "rgba(0, 0, 0, .2)"

          }
        }}
      >
        {children}

      </Box>

    </Box>
  )
}

export default Modal
