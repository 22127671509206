import React from 'react'
import {useRouteError, Link} from "react-router-dom"
import { PageNotFound, SomethingWentWrong } from './inner';
import { Box } from '@mui/material';
import siteDetails from '../utils/datas';

const {routeName} = siteDetails;

const ErrorPage = () => {
    const error : any = useRouteError();

    console.log(error);
  return (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100vh"
    >

        {error.status === 404? <PageNotFound /> : <SomethingWentWrong />}

        <p className=''>{error.statusText}</p>
        <Link style={{marginTop: "15px"}} to={routeName.home}>Back to home</Link>
      
    </Box>
  )
}

export default ErrorPage
