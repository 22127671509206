import React from 'react'
import {Grid} from "@mui/material";
import siteDetails from '../utils/datas';
import WhatWeDoCard from './WhatWeDoCard';

const {whatWeDo} = siteDetails;

const WhatWeDoListing = ({list, ...props}: {list?: {title: string, description: string, icon: React.ReactNode, backgroundImage?: any }[]}) => {

    if(!list){

        list = whatWeDo.map(item => ({
          title: item.title,
          description: item.description,
          icon: <img src={item.icon} alt="title icon" />,
          backgroundImage: item.backgroundImage
        }));

    }

  return <Grid container spacing={5} rowSpacing={3} {...props}>
      {list.map((listItem, key) => <Grid key={key} item xs={12} sm={6} md={4}>
          <WhatWeDoCard {...listItem} />
        </Grid>)}
    </Grid>;
}

export default WhatWeDoListing
