import React from 'react'
import { Box } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';
import siteDetails from '../utils/datas';

const {lotties, socials} = siteDetails;

const SiteMaintenance = () => {
  return (
    <>

        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{
            width: "100%",
            height: "100vh",
          }}
        >

          <Player
            src={lotties.maintenance}
            loop
            autoplay
          
          />

          <a href={`mailTo:${socials.mail.label}`} style={{margin: "0px", marginTop: "-10px", textAlign: "center", fontSize: "clamp(0.8rem, 5vw, 1rem)"}}>Contact Us</a>
          
        </Box>
    
    </>
  )
}

export default SiteMaintenance
